.orderSummary {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  width: 100%;
  /* justify-content: center; */
}
.products {
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 100px;
  padding-left: 50px !important;
  padding-right: 150px;
}
.orderSummaryTitle {
  font-size: 12px;
  font-weight: 500;
  color: #ec2025;
  font-style: italic;
}
.productTitle {
  font-size: 24px;
  font-weight: 500;
  color: #ec2025;
}
.priceTitle {
  font-size: 24px;
  font-weight: 500;
  color: #231f20;
}
.totalValue {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 24 */
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
}
.totalTitle {
  color: var(--primary-red, #ec2025);
  text-align: right;
  /* Italic/Med Italic 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 14px; /* 87.5% */
}
.paymentMethodLable {
  color: var(--primary-red, #ec2025);
  /* Italic/Med Italic 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 14px;
}
.radioPaymentMethod {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
  gap: 20px;
}
.radioPaymentMethod
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner,
.radioPaymentMethod
  :where(.css-1w4v3hc).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #ec2025;
  background-color: #ffffff;
}
:where(.css-dev-only-do-not-override-1w4v3hc)
  .ant-radio-wrapper
  .ant-radio-inner::after,
:where(.css-1w4v3hc) .ant-radio-wrapper .ant-radio-inner::after {
  box-sizing: border-box;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-block-start: -8px;
  margin-inline-start: -8px;
  background-color: #ec2025 !important;
  border-block-start: 0;
  border-inline-start: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: "";
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-radio-wrapper:hover
  .ant-radio-inner,
:where(.css-1w4v3hc).ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #ec2025;
}
.payTitle {
  color: var(--primary-black, #231f20);

  /* HeadLine/Bold 24 */
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  margin: 0 10px;
}
.paySubTitle {
  color: var(--primary-red, #ec2025);

  /* Italic/Med Italic 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  margin: 0 10px;
}
.cardPayment {
  margin-top: 2em;
  display: flex;
  gap: 20px;
}
.paymentForm {
  width: 90%;
}
.paymentForm .becomeVendorInput {
  height: 56px;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0;
}
.paymentForm .becomeVendorBtn {
  background-color: #ec2025;
  color: #fff;
  width: 100%;
  height: 56px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border-color: #fff;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}

.paymentForm .becomeVendorLable {
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}
.paymentForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input,
.paymentForm :where(.css-1w4v3hc).ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  padding-left: 10px;
  border-radius: 0;
  background-color: #ebebeb;
  outline: none;
  color: gray;
}
.paymentForm .becomeVendorBtn {
  background-color: #ec2025;
  color: #fff;
  width: 100%;
  height: 56px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  /* margin:10px 0 5px; */
  margin-top: 27px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border-color: #fff;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
.paymentForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.paymentForm
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #000;
  background-color: #fff;
  border-color: #ec2025;
}
.optional {
  color: var(--primary-black, #231f20);
  /* Italic/Med Italic 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 14px;
}
.shoppingBtn2 .CheckOut {
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  background: #ec2025;
  color: #fff;
  border: 1px solid #ec2025;
  /* padding: 1em 3em; */
  height: 56px;
  width: 390px;
}
@media screen and (max-width: 1480px) {
  .products {
    padding: 0 20px !important;
  }
  .shoppingBtn2 .CheckOut {
    padding: 4px 15px;
    font-size: 13px;
    height: 40px !important;
  }
}
