.automotive-div .gnvGf,
.automotive-property-container .gnvGf {
  margin: 0px !important;
}
.automotive-div .bBfHpH,
.automotive-property-container .bBfHpH {
  left: -45px !important;
  box-shadow: none !important;
  height: 30px;
  width: 30px;
}
.automotive-div .dZkckO,
.automotive-property-container .dZkckO {
  right: -45px !important;
  box-shadow: none !important;
  height: 30px;
  width: 30px;
}
.automotive-div .jAHQrP {
  bottom: 20px !important;
}
.featuresBusiness {
  margin-top: 12px;
  background-color: #ec20251c;
  padding: 5px 5px 10px 5px;
  border-radius: 5px;
}
.featuresBusiness .gnvGf {
  margin: 0 40px 0 25px !important;
}
.featuresBusiness .bhHnmT {
  margin: 0 30px 0 15px !important;
}
.featuresBusiness .bBfHpH {
  left: -6px !important;
  height: 25px;
  width: 25px;
}
.featuresBusiness .dZkckO {
  right: -5px !important;
  height: 25px;
  width: 25px;
}
.featuresBusiness .label {
  color: #ec2025;
  margin-bottom: 10px;
  margin-top: 10px;
}
.featuresBusiness .active {
  border: 1px solid #ec2025;
}

.property-name {
  white-space: pre-wrap;
  font-size: 22px;
}
.Whatsapp-btn {
  color: #fff !important;
  background-color: rgb(37 211 102) !important;
  border-color: rgb(37 211 102) !important;
  font-size: 18px;
}
.hover-Whats:hover {
  color: #231f20 !important;
  background-color: #fff !important;
  border-color: rgb(37 211 102) !important;
}
.hover-Whats:hover .anticon {
  color: #231f20 !important;
}
/* .Call-btn {
  color: #231f20 !important;
  background-color: #fff !important;
  border-color: rgb(37 211 102) !important;
  font-size: 18px;
}
.hover-Call:hover {
  color: #fff !important;
  background-color: rgb(37 211 102) !important;
  border-color: rgb(37 211 102) !important;
}
.hover-Call:hover .anticon {
  color: #fff !important;
} */
:where(.css-dev-only-do-not-override-1w4v3hc).ant-segmented
  .ant-segmented-item-selected,
:where(.css-1w4v3hc).ant-segmented .ant-segmented-item-selected {
  color: #ec2025 !important;
}
@media screen and (max-width: 767px) {
  .mobile-automotive {
    width: 90%;
    margin: 0 auto;
    margin-top: 75px;
    margin-bottom: 60px;
  }
}
