@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-layout-header {
  /* height: 64px; */
  padding-inline: 50px;
  color: rgba(0, 0, 0, 0.88) !important;
  /* line-height: 64px; */
  background: #ffffff !important;
  font-family: "Open Sans", sans-serif;
}
:where(.css-1w4v3hc).ant-layout-header {
  height: 64px;
  padding-inline: 50px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 64px;
  background: #fff;
}
.logo-search-div {
  display: flex;
  width: 100%;
  /* max-width: 80%; */
  align-items: center;
  gap: 10px;
}
.navbarIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 27px;
}
.navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.navIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin:0 auto 70px; */
  padding: 0;
  cursor: pointer;
}
.navIconTitle {
  display: flex;
  flex-direction: column;
  /* position: relative; */
}
.navIconTitle1 {
  font-size: 24px;
  padding: 0;
  font-weight: 500;
  color: #231f20;
  /* margin-bottom: -15px; */
}
.navInput {
  border-radius: 98px;
  /* margin: 35px 10px 0; */
  padding: 0 15px;
  height: 40px;
  width: 100% !important;
  color: #acaab6;
  border-color: #acaab6;
  background: #ebebeb;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover,
:where(.css-1w4v3hc).ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border-color: #acaab6 !important;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper:focus,
:where(.css-1w4v3hc).ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px rgb(5 145 255 / 0%) !important;
}
.navInput:focus,
.navInput:active {
  border-color: #acaab6 !important;
  box-shadow: 0 0 0 2px rgb(5 145 255 / 0%) !important;
}
.navIconTitle2 {
  font-size: 12px;
  font-weight: 300;
  color: #ec2025;
  position: absolute;
  margin-top: -20px;
}
.navBarRigt {
  /* width: 40%; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
.language {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  gap: 15px;
  margin-top: 10px;
  height: 20px;
  /* gap: 10px; */
}
.categories {
  overflow-y: auto;
  display: flex;
  /* flex-wrap: wrap !important; */
  /* justify-content: space-between; */
  align-items: center;
  gap: 0px 50px;
  /* margin-top: -10px; */
}
.view-product {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  border-radius: 7px;
  border: 1px solid #ec2025;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background-color: #fff;
  height: 40px;
  max-width: 100%;
}
.view-product:hover {
  color: #fff !important;
  background-color: #ec2025 !important;
  border-color: #ec2025 !important;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.itemTitle {
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
}
.itemTitle span {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 50px;
  width: 0%;
  height: 2px;
  background: #ec2025;
  transition: all 0.6s;
}

.itemTitle:hover span {
  width: 100%;
  z-index: 1000;
}
.categoriesItems {
  width: 100%;
  background: #ebebeb;
  padding: 10px 20px;
  display: flex;
  /* height: 300px; */
  box-shadow: 0 0 2px #231f20;
  z-index: 999;
  position: absolute;
  overflow: scroll;
  overflow-y: hidden;
  top: 120px;
}
.categoryName {
  font-size: 14px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  /* width: auto; */
}
.subCategories {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.subCategoryTitle {
  font-style: italic;
  color: #ec2025;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  /* height: 30px; */
}
.subCategoryTitle2 {
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  color: #231f20;
}
.subCategoryData {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 70px 0px 25px;
}
.fade-in {
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease 0.3s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.allCategories {
  /* width: 155px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px;
  /* opacity: 0; */
  box-shadow: 1px 1px 4px #0002;
  /* left: -155px; */
  background: #ffffff;
  /* overflow: hidden; */
  /* position: absolute; */
  /* z-index: 999; */
  /* top: 125px; */
}
.open {
  animation: width ease 0.3s;
  -webkit-animation: width ease 0.3s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}

@keyframes width {
  0% {
    width: 0;
  }
  40% {
    width: 50px;
  }
  60% {
    width: 80px;
  }
  80% {
    width: 100px;
  }
  100% {
    width: 155px;
  }
}
.allCatItem {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 500;
  color: #231f20;
  background: #ffffff;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.hoverCategory:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light:not(
    .ant-menu-horizontal
  )
  .ant-menu-submenu-title:hover {
  background-color: #ec2025;
  color: #ffffff;
}
.hoverCategory:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light:not(
    .ant-menu-horizontal
  )
  .ant-menu-submenu-title:active {
  background-color: #ec2025;
  color: #ffffff;
}
/* .hoverCategory li :hover  {
  background: #ec2025;
  color: #ffffff;
} */
.hoverCategory:hover .link-hover {
  color: #fff !important;
}
/* .hoverCategory:active li {
  background: #ec2025;
  color: #ffffff;
} */
.rigtArrow {
  font-size: 12px;
  color: #231f20;
}
.hoverCategory:hover > .allCatItem .rigtArrow {
  color: #ffffff;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-drawer .ant-drawer-body,
:where(.css-1w4v3hc).ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0px !important;
  overflow-x: hidden;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-dropdown .ant-dropdown-menu,
:where(.css-1w4v3hc).ant-dropdown .ant-dropdown-menu {
  padding: 0px;
  list-style-type: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  top: -15px !important;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-vertical
  .ant-menu-submenu-title,
:where(.css-1w4v3hc).ant-menu-vertical .ant-menu-submenu-title {
  padding-inline-end: 0 !important;
}
.banars {
  width: 100%;
  /* height: 300px; */
}

.banars .dZkckO {
  display: none !important;
}
.banars .bBfHpH {
  display: none !important;
}
.banars .dluTbn {
  overflow: hidden;
  margin: 0 !important;
}

.banars .gZzsDe {
  bottom: 10px;
}

@media all and (min-width: 1100px) and (max-width: 1250px) {
  .header-icon {
    width: 30px;
    height: 30px;
  }
  /* .navbarIcons {
                gap: 15px;
            } */
  .navIconTitle1 {
    font-size: 24px;
    padding: 0;
    font-weight: 500;
    color: #231f20;
    /* margin-bottom: -15px; */
  }
  .logo-search-div {
    /* width: 60%; */
  }
  /* .logo-img {
        width: 45%;
      } */
  .navInput {
    /* width: 55%; */
    border-radius: 98px;
    /* margin: 35px 10px 0; */
    padding: 0 15px;
    height: 40px;
    color: #acaab6;
    border-color: #acaab6;
  }
  .navBarRigt {
    /* width: 40%; */
  }
}
@media all and (min-width: 768px) and (max-width: 1099px) {
  .header-icon {
    width: 30px;
    height: 30px;
  }
  /* .navbarIcons {
            gap: 15px;
        } */
  .navIconTitle1 {
    font-size: 16px !important;
    padding: 0;
    font-weight: 500;
    color: #231f20;
    /* margin-bottom: -15px; */
  }
  .logo-search-div {
    /* width: 55%; */
  }
  .logo-img {
    /* width: 45%; */
  }
  .navInput {
    /* width: 55%; */
    border-radius: 98px;
    /* margin: 35px 10px 0; */
    padding: 0 15px;
    height: 40px;
    color: #acaab6;
    border-color: #acaab6;
  }
  .navBarRigt {
    width: 45%;
  }
}
@media all and (200px <= width <= 826px) {
  .navWrapper {
    flex-wrap: wrap;
  }
  .logo-search-div {
    width: 100%;
    /* margin-top: 10px; */
  }
  .navBarRigt {
    width: 100%;
  }
  .navInput {
    /* margin: 0 12px; */
  }
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title,
:where(.css-1w4v3hc).ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: #231f20;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.06);
}
:where(.css-1w4v3hc).ant-menu-light .ant-menu-item-selected,
:where(.css-1w4v3hc).ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.06);
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  color: #231f20;
}
:where(.css-1w4v3hc).ant-menu-light .ant-menu-item-selected,
:where(.css-1w4v3hc).ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #231f20;
}
.link-hover:hover {
  color: #fff !important;
}
.menueTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
  text-transform: capitalize;
}
.searchResults {
  width: 100%;
  position: absolute;
  top: 90px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  /* height: 300px; */
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  z-index: 999;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.itemSearch .subItem {
  cursor: pointer;
}
.itemSearch .subItem:hover {
  background-color: #eee;
  color: #000;
}
.allProducts {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}
.allProducts .searchBy {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-end;
  gap: 5px;
  padding-top: 10px;
}
.allProducts .productList {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  max-height: 230px;
  overflow: hidden;
  overflow-y: auto;
  padding-inline: 7px;
}
.allProducts .productList .productContent {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.allProducts .productList .imageDev {
  width: auto;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: cover;
  border-radius: 7px;
}
.allProducts .productList .des {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 2;
  /* width: 50%; */
}
.overLaySearh {
  /* background-color: #00000055; */
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  /* top: 100px; */
  top: 0;
  bottom: 0;
  z-index: 88;
  transition: all 0.33 ease;
  -webkit-transition: all 0.33 ease;
  -moz-transition: all 0.33 ease;
  -ms-transition: all 0.33 ease;
  -o-transition: all 0.33 ease;
}
html,
body {
  overflow: var(--noscroll);
}

@media screen and (max-width: 576px) {
  .allProducts .productList .imageDev {
    width: 100% !important;
  }
}
