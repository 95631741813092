.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #fff !important;
}
.containerCarousel1 {
  width: 100% !important;
}
/* .fPimMH{
    width: 592px !important;
}
.Carousel__Container-sc-hyhecw-0{
    width: 592px!important;
} */
.carouselImage {
  width: 100%;
  /* max-height: 500px; */
}
.carouselImageMobile {
  margin-bottom: 100px;
}
@media (max-width: 550px) {
  .containerCarousel1 {
    width: 100% !important;
  }
}

/************mobile***********/
.landing-main-mobile {
  width: 92%;
  margin: 10px auto;
}
.landing-main-mobile .header-div-mobile {
  display: flex;
  justify-content: space-between;
  padding: 5px 25px;
  align-items: center;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  max-height: 75px !important;
  /* height: 80px; */
  background: #fff;
  z-index: 1000;
}
.landing-main-mobile .header-div-mobile .squares-img {
  width: 8%;
  height: 25px;
}
.landing-main-mobile .header-div-mobile .main-logo-img {
  width: 45%;
  height: 65px;
}
.landing-main-mobile .header-div-mobile .man-img {
  width: 60px;
  height: 60px;
}
.landing-main-mobile .userName {
  margin-top: 85px;
}
.search-div-mobile {
  width: 100%;
  /* display: flex;
    flex-direction: column; */
  position: relative;
  /* margin-top: 30px; */
}
.search-mobile {
  width: 100%;
  padding: 1em 0.5em;
  border: none;
  outline: none;
  text-indent: 40px;
  border-radius: 7px;
  background-color: rgba(235, 235, 235, 1);
}
.search-mobile::placeholder {
  text-indent: 40px;
}
.btns-div {
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS devices */
  scrollbar-width: none; /* Firefox: hide standard scrollbar */
  -ms-overflow-style: none; /* IE/Edge: hide standard scrollbar */
}
.btns-div::-webkit-scrollbar {
  width: 0; /* Remove default scrollbar width */
  height: 0; /* Remove default scrollbar height */
}

/* For Firefox */
.btns-div:-moz-scrollbar {
  display: none;
}

.categories-btns-mobile {
  border: none;
  border-radius: 7px;
  margin: 1em 0.5em;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  z-index: 99;
  outline: none;
  cursor: pointer;
  position: relative;

  background: rgba(235, 235, 235, 1);
}
.btns-div button:first-child {
  margin: 1em 0.5em 1em 0em !important;
}
.new-products-div-mobile {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.new-products-div-mobile .productCard {
  width: 100%;
  margin: 0px;
  margin-bottom: 25px;
}
.new-products-div-mobile .productCard .productImage {
  width: 100%;
}

@media screen and (770px >= width >= 500px) {
  .landing-main-mobile .header-div-mobile .man-img {
    width: 74px !important;
  }
  .landing-main-mobile .header-div-mobile .main-logo-img {
    width: 45% !important;
    /* height: 75px; */
  }

  .landing-main-mobile .header-div-mobile .squares-img {
    width: 35px;
    height: 25px;
  }
}
.loadingBanners
  :where(
    .css-dev-only-do-not-override-1w4v3hc
  ).ant-skeleton.ant-skeleton-element,
.loadingBanners :where(.css-1w4v3hc).ant-skeleton.ant-skeleton-element {
  display: inline-block;
  width: 100% !important;
}
@media screen and (max-width: 400px) {
  .landing-main-mobile .userName {
    margin-top: 75px !important;
  }
  .landing-main-mobile .header-div-mobile .main-logo-img {
    width: 48% !important;
    /* height: 75px; */
  }
}
/* .new-products-div-mobile .productAddToCart{
    gap: 0px;
  }
  .new-products-div-mobile .productBtn {
    width: 80%;
  }
  .new-products-div-mobile .heartbutton{
    width: 18%;
  } */
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 994 !important;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}
/*********** Desktop ****************/
.landing-prev-arrow,
.landing-next-arrow {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 30px !important;
  width: 30px !important;
  background: transparent !important;
  border-radius: 50%;
  box-shadow: none !important;
  z-index: 1000;
  color: #ec2025;
  border: 3px solid #ec2025;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  transition: opacity 0.25s;
}
.landing-prev-arrow {
  left: initial;
  right: 5px !important;
}
.landing-next-arrow {
  left: 5px !important;
  right: initial;
}
.landing-prev-arrow:hover,
.landing-next-arrow:hover {
  opacity: 1;
  color: #ec2025;
  border: 3px solid #ec2025;
}
.dZkckO,
.bBfHpH {
  color: #ec2025;
  border: 3px solid #ec2025;
  /* opacity: 0 !important; */
}
/* .dZkckO:hover,
.bBfHpH:hover {
  opacity: 0.6 !important;
} */
.bBfHpH::before,
.dZkckO::before {
  color: #ec2025;
  border-top: 2px solid #ec2025 !important;
  border-right: 2px solid #ec2025 !important;
}
.eEKCvB {
  bottom: 10px !important;
}

/******************/
.carousel.carousel-slider .control-arrow {
  opacity: 0 !important;
}
.carousel.carousel-slider:hover .control-arrow {
  opacity: 0.8 !important;
}
.carousel .control-prev {
  margin-left: 10px !important;
}
.carousel .control-next {
  margin-right: 10px !important;
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  /* opacity: 0 !important; */
  width: 30px !important;
  height: 30px !important;
}
.carousel .control-arrow:focus,
.carousel .control-arrow:hover {
  color: #ec2025 !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #ec2025;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin-bottom: 10px !important;
}
.carousel.carousel-slider .control-arrow,
.carousel.carousel-slider .control-arrow:hover {
  /* background: rgba(0, 0, 0, 0.2); */
  background: unset !important;
  top: 45% !important;
  bottom: 40% !important;
  border-radius: 50% !important;
  /* width: 50px !important;
  height: 50px !important; */
  border: 3px solid #ec2025 !important;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #ec2025 !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #ec2025 !important;
}
.carousel .carousel-status {
  visibility: hidden !important;
}
.carousel .thumb {
  visibility: hidden !important;
}
/****************** arrow  carosal */
/* next */
.carousel .control-next.control-arrow:before {
  border-left: 0px !important;
}
.carousel .control-next:before,
.carousel.carousel-slider .control-next:before {
  /* margin: 0px 10px 15px 0px !important; */
  margin: 0px 0px 15px -5px !important;
  display: inline-block;
  /* border-top: 8px solid transparent; */
  /* border-bottom: 8px solid transparent; */
  content: "";
  border: solid #ec2025 !important;
  border-width: 0 3px 3px 0 !important;
  padding: 5px !important;
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg) !important;
}

/******* prev */
.carousel .control-prev.control-arrow:before {
  border-right: 0px !important;
}

.carousel .control-prev:before,
.carousel.carousel-slider .control-prev:before {
  /* margin: 0px 5px 15px 4px !important; */
  margin: 0px -6px 15px 0px !important;
  display: inline-block;
  /* border-top: 8px solid transparent; */
  /* border-bottom: 8px solid transparent; */
  content: "";
  border: solid #ec2025 !important;
  border-width: 0 3px 3px 0 !important;
  padding: 5px !important;
  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg) !important;
}

@media screen and (max-width: 767px) {
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 0 !important;
  }
}
