.splash {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.splashLogo {
	display: flex;
	align-items: center;
	gap: 6px;
	margin: 0 auto 60px;
	padding: 0;
	transition: ease 0.55s;
	-webkit-transition: ease 0.55s;
	-moz-transition: ease 0.55s;
	-ms-transition: ease 0.55s;
	-o-transition: ease 0.55s;
}
.splashLogoTitle {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	white-space: nowrap;
	transition: ease-in-out 0.55s;
	-webkit-transition: ease-in-out 0.55s;
	-moz-transition: ease-in-out 0.55s;
	-ms-transition: ease-in-out 0.55s;
	-o-transition: ease-in-out 0.55s;
}

.splashLogoTitle .logoTitle1 {
	font-size: 40px;
	padding: 0;
	font-weight: 500;
	color: #231f20;
}
.splashLogoTitle .logoTitle2 {
	font-family: "Source Sans 3", sans-serif;
	font-size: 16px;
	font-style: italic;
	margin-top: -8px;
	font-weight: 200;
	color: #231f20;
	padding-left: 5px;
}

/* div {
    display:inline-block;
    overflow:hidden;
    white-space:nowrap;
  } */

.splash:first-of-type {
	/* 
    For increasing performance 
    ID/Class should've been used. 
    For a small demo 
    it's okaish for now 
  */
	animation: showup 7s infinite;
}

.splashLogoTitle:last-of-type {
	width: 0px;
	animation: reveal 7s infinite;
}

/* div:last-of-type span {
    margin-left:-355px;
    animation: slidein 7s infinite;
  } */

@keyframes showup {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes slidein {
	0% {
		margin-left: -800px;
	}
	20% {
		margin-left: -800px;
	}
	35% {
		margin-left: 0px;
	}
	100% {
		margin-left: 0px;
	}
}

@keyframes reveal {
	0% {
		opacity: 0;
		width: 0px;
	}
	20% {
		opacity: 1;
		width: 0px;
	}
	30% {
		width: 220px;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		width: 220px;
	}
}
