.addProductImage .ant-upload,.addProductImage .ant-upload-select {
    width: 100% !important;
    height: 250px !important;
    overflow: hidden;
}
/* .Carousel__RailWrapper-sc-hyhecw-1, .bGpMiD{
    margin: 0px 40px;
}  */
/* .addProductCaresoul .ArrowButton__Button-sc-1ikb0hj-1, .dZkckO{
    border: 3px solid gray !important;
} */

/* .addProductCaresoul .ArrowButton__Button-sc-1ikb0hj-1::before, .addProductCaresoul .dZkckO::before, .addProductCaresoul .dZkckO::before  {
    border-top: 3px solid gray !important;
    border-right: 3px solid gray !important;
} */
/* .addProductCaresoul .eEKCvB{
    display: none !important;
} */
.addProductCancelBtn:hover {
background-color: #ec2025 !important;
color: white !important;
border-color: #ec2025 !important;
}

/* @media all and (max-width: 767px){
   .subCat .Carousel__RailWrapper-sc-hyhecw-1, .bGpMiD{
        margin: 0px !important;
    } 
} */

.carouselProducts .addProductImage {
    display: flex;
    flex-direction: row;
    gap: 30px;
    /* margin-top: 20px; */
    padding-block: 10px;
    overflow: auto ;
}
.carouselProducts .addProductImage .Image {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    border-radius: 7px;
    background: var(--primary-light-gray, #EBEBEB);
    overflow: hidden;
    background-size: auto 120px !important;
    background-position: center;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
}