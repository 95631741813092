@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

.productCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* width:186px; */
  /* margin:0  20px ; */
}
.productImage {
  width: 186px;
  height: 186px;
  overflow: hidden;
  max-width: 100%;
  flex-shrink: 0;
  background: #ebebeb;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.productDescription .desc {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  color: #231f20;
  white-space: wrap;
  /* text-overflow: ellipsis; */
  overflow: hidden;
}
.productRate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.starIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.rate {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  /* line-height: 14px;  */
  white-space: nowrap;
  /* margin-top: 10px; */
}
.productAddToCart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.productAddToCart .productBtn {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 0px;
  /* flex: 1 0 0; */
  border-radius: 7px;
  border: 1px solid #ec2025;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background-color: #fff;
}
.cartIcon {
  font-size: 20px;
  margin-right: 0px;
}
.cartIcon:hover {
  color: #fff;
}
.heartIcon {
  font-size: 15px;
}
.productAddToCart
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.productAddToCart
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff !important;
  background-color: #ec2025 !important;
  border-color: #ec2025 !important;
}
.heartbutton
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.heartbutton
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):active,
.heartbutton
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover,
.heartbutton
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):active {
  color: #ec2025 !important;
  background-color: #fff !important;
  border-color: #ec2025 !important;
}
.productRate :where(.css-dev-only-do-not-override-1w4v3hc).ant-rate,
.productRate :where(.css-1w4v3hc).ant-rate {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #ec2025;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: inline-block;
  outline: none;
}
.productRate
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-rate
  .ant-rate-star:not(:last-child),
.productRate :where(.css-1w4v3hc).ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 5px !important;
}
.container {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  gap: 10px;
}
.carouselProducts {
  margin-bottom: 30px;
}
.carouselProducts .bBfHpH {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 30px !important;
  width: 30px !important;
  background: transparent !important;
  border-radius: 50%;
  border: 3px solid #ec2025;
  box-shadow: none !important;
  z-index: 10;
  cursor: pointer;
  font-size: 10px;
  /* opacity: 0.6;
    -webkit-transition: opacity 0.25s; */
  transition: opacity 0.25s;
  left: -40px !important;
  right: initial;
}

.carouselProducts .bBfHpH::before {
  content: "";
  height: 10px;
  width: 10px;
  background: transparent;
  border-top: 3px solid #ec2025 !important;
  border-right: 3px solid #ec2025 !important;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-25%, -50%) rotate(-135deg);
  -ms-transform: translate(-25%, -50%) rotate(-135deg);
  transform: translate(-25%, -50%) rotate(-135deg);
}
.carouselProducts .dZkckO::before {
  content: "";
  height: 10px;
  width: 10px;
  background: transparent;
  border-top: 3px solid #ec2025 !important;
  border-right: 3px solid #ec2025 !important;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-75%, -50%) rotate(45deg);
  -ms-transform: translate(-75%, -50%) rotate(45deg);
  transform: translate(-75%, -50%) rotate(45deg);
}

.carouselProducts .dZkckO {
  position: absolute;
  top: calc(50% - 17.5px);
  height: 30px !important;
  width: 30px !important;
  background: transparent !important;
  border-radius: 50%;
  box-shadow: none !important;
  z-index: 10;
  border: 3px solid #ec2025;
  cursor: pointer;
  font-size: 10px;
  opacity: 0.6;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  left: initial;
  right: -40px !important;
}
.carouselProducts .eEKCvB {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  bottom: -35px !important;
  height: 10px;
  width: 100%;
  line-height: 10px;
  text-align: center;
}
.carouselProducts .dluTbn {
  overflow: hidden;
  margin: 0px 0px 0px 0px !important;
}

@media all and (max-width: 768px) {
  .carouselProducts .bBfHpH {
    left: 12px !important;
  }
  .carouselProducts .dZkckO {
    right: 12px !important;
  }
  .bGpMiD {
    overflow: hidden;
    margin: 0 36px 15px 36px !important;
  }
  .glOUzD {
    overflow: hidden;
    margin: 0 36px 15px 36px !important;
  }
  .carouselProducts {
    margin-bottom: 5px;
  }
}

/* @media screen and (max-width: 500px)
{
.cYucAd {
    grid-template-columns: repeat(2,70%) !important;
    grid-template-rows: 1fr;
}
} */

.carousel-fix-margin > div > :nth-child(2) {
  /* margin: 0px 35px !important; */
  margin: 0px !important;
}
