.product-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
}
.timer-container {
  position: absolute;
  top: 10px;
  left: 5px;
  z-index: 2;
  /* background-color: #acaab6; */
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.timer-icon {
  color: #ec2025;
}
.timer-text {
  color: #231f20;
  text-shadow: 0px 0px 7px #ffffff;
}
.product-featured-tag {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 2;
}
.product-desc {
  height: 33px;
}
.product-desc .read-more-button {
  display: inline-block;
}
.productBtn:hover,
.productBtn:active,
.productBtn:focus {
  color: #fff !important;
  background-color: #ec2025 !important;
  border-color: #ec2025 !important;
}
.productTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productName {
  font-size: 18px;
  font-weight: 500;
  color: #ec2025;
  white-space: nowrap;
}
.productPrice {
  font-size: 18px;
  font-weight: 500;
  color: #ec2025;
}
.price_2 {
  margin-right: 5px;
  color: #acaab6;
  text-decoration: line-through;
  text-decoration-color: #ec2025;
  font-size: 15px;
}
.flex-center-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-img {
  max-width: 100%;
  /* min-width: 160px; */
  /* min-height: 150px;
  max-height: 150px; */
  height: 60%;
  width: 100%;
  border-radius: 10px;
}
.productDescription .desc {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  color: #231f20;
}
.productRate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin: 5px 0px; */
}
.productRate :where(.css-dev-only-do-not-override-1w4v3hc).ant-rate,
.productRate :where(.css-1w4v3hc).ant-rate {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #ec2025;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: inline-block;
  outline: none;
}
.productRate
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-rate
  .ant-rate-star:not(:last-child),
.productRate :where(.css-1w4v3hc).ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 5px !important;
}
.rate {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  margin-left: 10px;
  /* line-height: 14px;  */
  white-space: nowrap;
  /* margin-top: 10px; */
}
.productAddToCart .productBtn,
.productBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  padding: 8px 16px;
  border-radius: 7px;
  border: 1px solid #ec2025;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background-color: #fff;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .productAddToCart .productBtn,
  .productBtn {
    font-size: 12px;
  }
  .rate {
    margin-left: 1px;
  }
  .product-img {
    height: 50%;
  }
}

@media screen and (max-width: 768px) {
  .product-img {
    height: 160px !important;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .productAddToCart .productBtn,
  .productBtn {
    padding: 4px 4px !important;
    font-size: 12px !important;
  }
  /* .productCard {
    margin: 0 !important;
  } */
}
