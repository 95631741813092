@tailwind utilities;
@import "react-alice-carousel/lib/alice-carousel.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 15px;
  width: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 0px;
  background: #ccc;
  border-radius: 15px;
  transition: ease 0.66s;
  -webkit-transition: ease 0.66s;
  -moz-transition: ease 0.66s;
  -ms-transition: ease 0.66s;
  -o-transition: ease 0.66s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-select-dropdown
  .ant-select-item-option-content {
  text-transform: capitalize !important;
}
:where(.css-1w4v3hc).ant-select-dropdown .ant-select-item-option-content {
  text-transform: capitalize !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-select-multiple
  .ant-select-selection-item-content {
  text-transform: capitalize !important;
}
:where(.css-1w4v3hc).ant-select-multiple .ant-select-selection-item-content {
  text-transform: capitalize !important;
}
:where(
    .css-dev-only-do-not-override-1w4v3hc
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-1w4v3hc
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  text-transform: capitalize !important;
}
:where(.css-1w4v3hc).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
:where(.css-1w4v3hc).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  text-transform: capitalize !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e7e3e3 !important;
}
:where(.css-1w4v3hc).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e7e3e3 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #ec2025 !important;
}
:where(.css-1w4v3hc).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #ec2025 !important;
}

:where(
    .css-dev-only-do-not-override-1w4v3hc
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
:where(
    .css-dev-only-do-not-override-1w4v3hc
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
:where(.css-1w4v3hc).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
:where(.css-1w4v3hc).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: #ec2025 !important;
}
