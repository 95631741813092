ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.card-container {
  width: 100%;
  border-radius: 7px;
}

.item-header {
  max-width: 100%;
  min-height: 1px;
  position: relative;
}
.tags-wrap {
  position: absolute;
  top: 5px;
  right: 2px;
  z-index: 2;
}
.featured-tag {
  position: absolute;
  top: 5px;
  z-index: 2;
  left: 5px;
}
/* .listing-image-wrap {
  position: relative;
}
.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); 
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
} */
.img-fluid {
  max-width: 100%;
  height: 220px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 10px;
}
.tags-wrap span {
  text-transform: capitalize;
}
.item-price-wrap {
  position: absolute;
  bottom: 10px;
  left: 5px;
  color: #fff;
  font-weight: 600;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  padding: 5px 10px;
  border-radius: 5px;
}
.item-price-wrap .item-price {
  font-size: 13px;
  line-height: 20px;
}
.item-body {
  padding: 10px 5px;
}
.item-title {
  font-weight: 500;
  color: #ec2025;
  cursor: pointer;
  /* line-height: 1.2; */
  text-transform: capitalize;
  font-size: 16px;
  text-align: left;
}
.item-address {
  color: #231f20;
  font-size: 12px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  font-style: normal;
  line-height: inherit;
  text-align: left;
  /* height: 30px; */
}
.item-amenities {
  padding: 15px 0 0;
  display: flex;
  /* -ms-flex-flow: wrap;
  flex-flow: wrap; */
  /* font-size: 14px; */
  line-height: 16px;
  font-weight: 500;
  row-gap: 10px;
}
.item-amenities li:first-of-type {
  padding-top: 0;
  padding-right: 10px;
  padding-left: 0;
  padding-bottom: 0;
}
.item-amenities li {
  display: block;
  border-right: 1px solid #dce0e0;
  padding: 0 10px;
  margin-right: 0;
  /* font-size: 14px; */
  font-weight: 300;
  color: #636363;
  white-space: nowrap;
  width: calc(100% / 3.2);
}
.item-amenities li:last-child {
  border-right: 0px;
}
.item-amenities li div {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #222222;
  display: flex;
  align-items: center;
}
.item-amenities li div .item-icon {
  display: inline-block;
  margin-left: 5px;
}
.item-amenities li p {
  font-size: 13px;
}
@media screen and (max-width: 850px) {
  .img-fluid {
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .tags-wrap {
    right: 0px;
  }
  .tags-wrap span {
    right: 0px;
    font-size: 10px;
  }
  .img-fluid {
    height: 150px;
  }
  .item-price-wrap {
    padding: 5px 5px;
  }
  .item-price-wrap .item-price {
    font-size: 12px;
  }
  .item-title {
    font-size: 14px;
  }
  .item-address {
    font-size: 12px;
  }
  .item-amenities {
    padding: 1px;
  }
  .item-amenities li {
    padding: 0 2px;
    width: 32%;
    /* padding-right: 10px; */
  }
  .item-amenities li div {
    font-size: 14px;
    margin-bottom: 2px;
  }
  .item-amenities li p {
    font-size: 9px;
  }
}
