.gallery {
  height: 100% !important;
}
.gallery .carousel-root {
  height: 100% !important;
}
.gallery .carousel.carousel-slider {
  height: 100% !important;
}
.gallery .carousel .slider-wrapper {
  height: 100% !important;
}
.gallery .carousel .slider.animated {
  height: 100% !important;
}
.gallery .carousel .slide img {
  object-fit: cover;
}
