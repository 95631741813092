.footer-div-mobile{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    position: fixed;
    bottom: 0;
    z-index: 999;
    padding: 12px 25px 12px 25px;
    border-radius: 15px 15px 0px 0px;
    /* height: 84px; */
    background: #fff;
    -webkit-border-radius: 15px 15px 0px 0px;
    -moz-border-radius: 15px 15px 0px 0px;
    -ms-border-radius: 15px 15px 0px 0px;
    -o-border-radius: 15px 15px 0px 0px;
    box-shadow: 0px -2px 36px 0px rgba(0, 0, 0, 0.189);
    box-sizing: border-box;
    width: 100%;

}
.footer-icon{
    color: #231F20;
    font-size: 30px;
    cursor: pointer;
}

@media screen and (max-width:440px) {
    .footer-div-mobile {
     padding: 12px 25px 12px 25px;
    }
}