@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}
.categoriesComp {
  /* margin-top: 20px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.categoriesComp .categoriesFilter {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
/* .categoriesComp .categoriesList {
  width: 70%;
} */
.categoryFilterTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* padding: 0 16px; */
}
.categoryFilterTitle .title1 {
  color: #231f20;
  font-size: 24px;
  font-weight: 700;
}
.categoriesDivider {
  border-color: #ec2025 !important;
  margin: 10px 0 !important;
}
.dividerPading {
  /* padding: 0 16px; */
}
.categorisFilterCollapse
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  padding-inline-start: 0px;
  padding: 0px;
}
.categorisFilterCollapse
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0px;
}
.categoriesOffer {
  display: flex;
  flex-direction: column;
  gap: 7px;
  /* margin:20px 0; */
}
.categoriesOffer .offerTitle1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 16;
  font-weight: 500;
}
.categoriesItemCollapse {
  font-size: 20px;
  font-weight: 700;
  color: #231f20;
}
/* .categoriesOffer .offerTitle1  */
.expandIcon {
  font-size: 20px;
}
.itemCollapse {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.categoeyTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16;
  font-weight: 500;
  color: #231f20;
  justify-content: space-between;
}
.brandTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16;
  font-weight: 500;
  color: #231f20;
  justify-content: space-between;
}
.selectCat {
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
}
.searchBrandIcon {
  font-size: 22px;
  color: #acaab6;
}
.brandSearchInput {
  color: #acaab6 !important;
  border-bottom: #acaab6 !important;
  font-size: 16px;
  font-weight: 500;
  border: none;
}
.inputBrandSearch
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input,
.inputBrandSearch
  :where(.css-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  padding-left: 10px;
  border-radius: 0;
  background-color: transparent !important;
  outline: none;
  color: gray;
}
.inputBrandSearch
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover,
.inputBrandSearch
  :where(.css-1w4v3hc).ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border-color: #fff !important;
}
.dividerSearch {
  width: 90%;
}
.searchDivider {
  border-color: #acaab6 !important;
  margin-top: 5px;
  margin-left: 10px;
}
.noteLeft {
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  color: #ec2025;
  /* float: left; */
  cursor: pointer;
}
.sizesCollapse {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.sizeTitle {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 47px;
  height: 47px;
  background-color: #ebebeb;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #ebebeb;
}
.colorsCollapse {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.colorTitle {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: 47px;
  height: 47px;
  cursor: pointer;
}
.byPrice {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-slider
  .ant-slider-handle::before,
:where(.css-1w4v3hc).ant-slider .ant-slider-handle::before {
  content: "";
  position: absolute;
  inset-inline-start: -2px;
  inset-block-start: -2px;
  width: 14px;
  height: 14px;
  background-color: transparent;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-slider
  .ant-slider-handle::after,
:where(.css-1w4v3hc).ant-slider .ant-slider-handle::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 10px;
  height: 10px;
  background-color: #ec2025 !important;
  box-shadow: 0 0 0 2px #ec2025 !important;
  border-radius: 50%;
  cursor: pointer;
  transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s,
    height 0.2s, box-shadow 0.2s;
}

.categoriesList {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.categoriesListTitle {
  /* width: 85%; */
  margin: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.categoriesListTitle .catTitle1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.categoriesListTitle .catTitle1 span {
  font-size: 24px;
  font-weight: 700;
  color: #ec2025;
}
.categoriesListTitle .catTitle1 p {
  font-size: 16px;
  font-weight: 500;
  color: #231f20;
}
.categoriesListTitle .catTitle2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.categoriesListTitle .catTitle2 > span {
  font-size: 16px;
  font-weight: 500;
  color: #ec2025;
}
.categoriesListTitle .catTitle2 > p {
  font-size: 16px;
  font-weight: 500;
  color: #231f20;
}
.dropDownCat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.listOfProducts {
  width: 100%;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box,
:where(.css-1w4v3hc).ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-block: 5px 12px !important;
}
@media screen and (max-width: 1000px) {
  /* .categoriesComp .categoriesFilter {
    width: 35%;
  }
  .categoriesComp .categoriesList {
    width: 65%;
  } */
}
@media screen and (max-width: 767px) {
  /* .categoriesComp {
    width: 90%;
    margin: 0 auto;
  }
  .categoriesComp .categoriesFilter {
    width: 40%;
  }
  .categoriesComp .categoriesList {
    width: 80%;
  } */

  .categoriesItemCollapse {
    font-size: 16px;
  }
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box,
  :where(.css-1w4v3hc).ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-block: 0px 1px !important;
  }
}
