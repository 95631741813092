.real-state-div .gnvGf,
.proerty-container .gnvGf {
  margin: 0px !important;
}
.real-state-div .bBfHpH,
.proerty-container .bBfHpH {
  left: -45px !important;
  box-shadow: none !important;
  height: 30px;
  width: 30px;
}
.real-state-div .dZkckO,
.proerty-container .dZkckO {
  right: -45px !important;
  box-shadow: none !important;
  height: 30px;
  width: 30px;
}
.real-state-div .jAHQrP {
  bottom: 20px !important;
}
.property-name {
  white-space: pre-wrap;
  font-size: 22px;
}
.Whatsapp-btn {
  color: #fff !important;
  background-color: rgb(37 211 102) !important;
  border-color: rgb(37 211 102) !important;
  font-size: 18px;
}
.hover-Whats:hover {
  color: #231f20 !important;
  background-color: #fff !important;
  border-color: rgb(37 211 102) !important;
}
.hover-Whats:hover .anticon {
  color: #231f20 !important;
}
/* .Call-btn {
  color: #231f20 !important;
  background-color: #fff !important;
  border-color: rgb(37 211 102) !important;
  font-size: 18px;
}
.hover-Call:hover {
  color: #fff !important;
  background-color: rgb(37 211 102) !important;
  border-color: rgb(37 211 102) !important;
}
.hover-Call:hover .anticon {
  color: #fff !important;
} */

@media screen and (max-width: 767px) {
  .mobile-real-estate {
    width: 90%;
    margin: 0 auto;
    margin-top: 75px;
    margin-bottom: 60px;
  }
}
