.divider {
	display: flex;
	align-items: center;
	/* padding: 20px; */
}
.left {
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	color: #231f20;
	line-height: 16px; /* 100% */
	white-space: nowrap;
}
.right {
	/* position: absolute; */
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	color: #231f20;
	line-height: 16px; /* 100% */
	/* border-color: #EC2025 !important; */
	/* width: 100px;  */
	white-space: nowrap;
	cursor: pointer;
}
.line {
	width: 100% !important;
	height: 1px;
	background-color: #ec2025;
	margin: auto;
	margin-bottom: 20px;
	margin-top: 20px;
	margin-left: 20px;
	margin-right: 20px;
}
@media screen and (max-width: 768px) {
	/* .divider {
            padding:20px 5px !important ;
        } */
}
