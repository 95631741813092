.product {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.productGalary {
  margin-top: 2em;
}
.productCarousel {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  /* height: 80%; */
  height: 400px;
}
.imageCard {
  width: 65%;
  /* height: 592px; */
  box-shadow: 0 0 4px gray;
  overflow: hidden !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  /* flex-shrink: 0; */
  border-radius: 15px;
  /* background: var(--primary-light-gray, #ebebeb); */
  /* display: flex; */
  /* justify-content: center; */
  /* padding: 30px; */
  max-height: 400px;
}
.imageCard .img {
  /* width: auto !important; */
  object-fit: cover;
  /* height: 100% !important; */
  background-position: center !important;
  background-size: auto 100% !important;
  background-repeat: no-repeat !important;
}
/* .imageSelected {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  max-height: 450px;
  padding: 8px;
  padding-top: 0 !important;
} */
.imageSelected {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  /* flex-wrap: wrap; */
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  padding: 0px 8px 0px 8px;
}
.imageSelected .oneImage {
  width: 100px;
  /* height: 93px; */
  height: calc(100% / 3.7);
  display: flex;
  justify-content: center;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  /* padding: 30px; */
  flex-shrink: 0;
  cursor: pointer;
  overflow: hidden;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  box-shadow: 0 1px 2px #00000075;
}
.imageSelected .oneImage img {
  border-radius: 15px;
  width: 100%;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.productData {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-wrap: wrap;
}
.productDataDetails {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.productDataDetails .productName {
  /* overflow: hidden; */
  color: var(--primary-black, #231f20);
  text-overflow: ellipsis;
  /* HeadLine/Bold 36 */
  font-family: Open Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.productDataDetails .rate {
  color: var(--primary-black, #231f20);
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productDataDetails .productrate {
  display: flex;
  gap: 5px;
}
.productDataDetails .star {
  font-size: 18px;
}
.productPrice {
  display: flex;
  gap: 5px;
  align-items: flex-end;
  flex-wrap: wrap;
}
.productPrice .title1 {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 36 */
  font-family: Open Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.productPrice .title2 {
  color: var(--primary-black, #231f20);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  position: relative;
}
.productColor {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  gap: 10px;
}
.productColor .title1 {
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.productColor .title2 {
  color: var(--primary-red, #ec2025);
  /* HeadLine/Bold 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.productColor .colors {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.divcolor {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 7px;
  box-shadow: 0 0 2px #231f20;
}
.productColor .colors .colorDiv {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 7px;
  box-shadow: 0 0 2px #231f20;
}
.productSize {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productSize .title1 {
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.productSize .title2 {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.productSize .sizes {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.divsize {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  background: var(--primary-light-gray, #ebebeb);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primary-black, #231f20);
  text-align: center;
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productSize .sizes .sizeDiv {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  background: var(--primary-light-gray, #ebebeb);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primary-black, #231f20);
  text-align: center;
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productDelivery {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productDelivery .title1 {
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.productDelivery .title2 {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.productDelivery .deliveryDiv {
  display: flex;
  width: 90%;
  padding: 11px 16px;
  gap: 5px;
  justify-content: space-around;
  align-items: center;
  border-radius: 7px;
  background: var(--primary-light-gray, #ebebeb);
}
.productDelivery .deliveryDiv .text {
  color: var(--primary-black, #231f20);
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productDelivery .deliveryDiv .text span {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
.productDelivery .deliveryDiv .locationIcon .locIcon {
  color: #ec2025 !important;
  font-size: 20px;
  border-color: #ec2025;
}
.paymentForm
  .shoppingBtn
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.paymentForm
  .shoppingBtn
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff;
  border-color: #ec2025;
  background: #ec2025;
}
.productRequest {
  display: flex;
  flex-direction: column;
  /* gap: 10px;  */
}
.productRequest .title1 {
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.totalPriceSection {
  /* display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap; */
}
.totalPriceSection .totalPrice {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.totalPriceSection .totalPrice :first-child {
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.totalPriceSection .totalPrice :last-child {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 24 */
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.totalPriceSection .specificationDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.totalPriceSection .specificationDiv :first-child {
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.totalPriceSection .specificationDiv :last-child {
  width: fit-content;
  /* padding: 0 20px; */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.totalPriceSection .specificationDiv :last-child .specifications {
  width: fit-content;
  color: #fff;
}
.totalPriceSection .addToCart {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productDescripe .details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productDescripe .details :first-child {
  color: var(--primary-black, #231f20);
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productDescripe .details :last-child {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.productDescripe .details .statusDetails {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}
.productDescripe .details .statusDetails .title {
  color: var(--primary-black, #231f20);
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  gap: 25px;
  align-items: center;
}
.productDescripe .details .statusDetails .supTitle {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
.productDescripe .details .genderDetails {
  display: flex;
  flex-direction: column;
  /* justify-self: flex-start; */
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.productDescripe .details .genderDetails .title {
  color: var(--primary-black, #231f20);
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  gap: 25px;
  align-items: center;
}
.productDescripe .details .genderDetails .supTitle {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
.productDescripe .descriptionText {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productDescripe .descriptionText :first-child {
  color: var(--primary-black, #231f20);
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productDescripe .descriptionText :last-child {
  overflow: hidden;
  color: var(--primary-black, #231f20);
  text-overflow: ellipsis;
  white-space: normal;
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productDescripe .productReview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productDescripe .productReview :first-child {
  color: var(--primary-black, #231f20);
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productDescripe .productReview :nth-child(2) {
  display: flex;
  gap: 0px;
  align-items: center;
}
.productDescripe .productReview :nth-child(2) img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(30, 30, 30, 0.35);
}
.productDescripe .productReview :nth-child(3) {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.productDescripe .productReview :nth-child(3) span {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.productDescripe .productSeller {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.productDescripe .productSeller :first-child {
  color: var(--primary-black, #231f20);
  /* Body/Medium 12 */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.productDescripe .productSeller :last-child {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.productDescripe .productSeller :last-child img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(30, 30, 30, 0.35);
}
.productDescripe .productSeller :last-child .sellerData {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.productDescripe .productSeller :last-child .sellerData :first-child {
  color: var(--primary-black, #231f20);
  /* HeadLine/Bold 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.productDescripe .productSeller :last-child .sellerData :last-child {
  color: var(--primary-black, #231f20);
  /* Body/Medium 16 */
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.imgSkelton
  :where(
    .css-dev-only-do-not-override-1w4v3hc
  ).ant-skeleton.ant-skeleton-element,
.imgSkelton :where(.css-1w4v3hc).ant-skeleton.ant-skeleton-element {
  display: inline-block;
  width: 100% !important;
  height: 100% !important;
}
/* .product-add-to-cart
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: #ec2025 !important;
  border-color: #ec2025 !important;
  background: white !important;
} */
@media screen and (max-width: 610px) {
  /* .imageCard { 
    width: fit-content;
    

  } */
  .imgSkelton {
    width: 100% !important;
    height: 200px !important;
  }
  .imageCard {
    width: 100% !important;
    height: 200px !important;
  }
  .imageSelected {
    flex-direction: row;
  }
}
@media screen and (max-width: 992px) {
  .imageCard {
    width: 65%;
    height: 400px;
  }
}
.carouselModal:where(.css-dev-only-do-not-override-1w4v3hc).ant-modal
  .ant-modal-content,
.carouselModal:where(.css-1w4v3hc).ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff00 !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: none !important;
  pointer-events: auto;
  padding: 0 !important;
}

.carouselModal:where(.css-dev-only-do-not-override-1w4v3hc).ant-modal
  .ant-modal-close,
.carouselModal:where(.css-1w4v3hc).ant-modal .ant-modal-close {
  position: absolute;
  top: -52px !important;
  inset-inline-end: 17px;
  z-index: 1010;
  padding: 0;
  color: rgb(0 0 0) !important;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  background: #0000002b !important;
  border-radius: 15px !important;
  width: 34px !important;
  height: 34px !important;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}
.productMobile .productMpbileData {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  max-width: 100%;
  /* width: fit-content; */
  margin-bottom: 20px;
}
.productMobile .productMpbileData .productName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* overflow: hidden; */
  color: var(--primary-red, #ec2025);
  /* text-overflow: ellipsis; */
  /* HeadLine/Bold 24 */
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
}
.productMobile .productMpbileData .pricetitle {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}
.productMobile .productMpbileData .pricetitle span {
  color: var(--primary-black, #231f20);
  text-align: right;
  /* HeadLine/Bold 24 */
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
}
.productMobile .productrate {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.productMobile .productMobileCarousel {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  margin-bottom: 20px;
  padding-block: 8px;
}
.productMobile .productMobileCarousel .prodImg {
  display: flex;
  width: 220px;
  height: 220px;
  /* padding: 32.019px 15.933px 32.591px 15.933px; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 7px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  object-fit: cover;
}
.productMobile .productMobileCarousel .prodImg .img {
  width: 220px;
  height: 220px;
  border-radius: 7px;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  background-position: center !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
:where(.css-1w4v3hc).ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #ec2025 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
:where(.css-1w4v3hc).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  /* height: 56px;
  padding: 11px 16px; */
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
:where(.css-1w4v3hc).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  border-radius: 7px;
  background: #fff;
  /* border: 1px solid #d9d9d9; */
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-select-single
  .ant-select-selector,
:where(.css-1w4v3hc).ant-select-single .ant-select-selector {
  box-sizing: border-box;
  margin: 0;
  /* padding: 0; */
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  display: flex;
  /* border-radius: 6px; */
}
.carouselHeight {
  height: 100% !important;
}
.carouselImageZoom {
  width: 100%;
  background-size: auto 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}
.overLayImage {
  background-size: auto 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}
.carodsel_more {
  width: 100% !important;
}
.alice-carousel__next-btn {
  position: absolute !important;
  right: 50px !important;
  width: auto !important;
  bottom: 50% !important;
}
.alice-carousel__prev-btn {
  position: absolute !important;
  left: 50px !important;
  width: auto !important;
  bottom: 50% !important;
}
.alice-carousel__next-btn-item {
  background-color: #0000002b !important;
  cursor: pointer !important;
  color: #000 !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  font-weight: 600;
  line-height: 2.5 !important;
  width: 34px !important;
  height: 34px !important;
  border: 0;
  outline: 0;
  text-align: center !important;
  transition: color 0.2s !important;
  -webkit-transition: color 0.2s !important;
  -moz-transition: color 0.2s !important;
  -ms-transition: color 0.2s !important;
  -o-transition: color 0.2s !important;
}
.alice-carousel__prev-btn-item {
  background-color: #0000002b !important;
  cursor: pointer !important;
  color: #000 !important;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  font-weight: 600;
  line-height: 2.5 !important;
  width: 34px !important;
  height: 34px !important;
  border: 0;
  outline: 0;
  text-align: center !important;
  transition: color 0.2s !important;
  -webkit-transition: color 0.2s !important;
  -moz-transition: color 0.2s !important;
  -ms-transition: color 0.2s !important;
  -o-transition: color 0.2s !important;
}
.product-add-to-cart
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):active {
  color: #fff !important;
  border-color: #ec2025 !important;
}
/* .item_class {
  width: 100% !important;
} */

@media screen and (max-width: 1000px) {
  .divsize {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 768px) {
  .divsize {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .productMobile .productMpbileData .productName {
    font-size: 18px;
    margin-top: 15px;
  }
  .productMobile .productMpbileData .pricetitle span {
    font-size: 18px;
    margin-top: 15px;
  }
  .productMobile .productrate {
    margin-bottom: 0px;
  }
  .my-profile-btns-mobile .infoBtn {
    height: 40px;
    margin: 5px 0px;
    padding: 4px 15px;
  }
  .divcolor {
    width: 26px;
    height: 26px;
  }
  .divsize {
    width: 45px;
    height: 45px;
    font-size: 12px;
  }
  .productMobile .productMobileCarousel {
    margin-bottom: 0px;
  }

  .becomeVendorInput {
    height: 40px !important;
    margin: 0px;
  }
}
