@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

.linkClass {
  display: flex !important;
  align-items: center !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-style: italic !important;
  /* gap: 10px   !important; */
}
.linkClass:hover {
  background-color: transparent !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-breadcrumb a,
:where(.css-1w4v3hc).ant-breadcrumb a {
  font-size: 15px !important;
  font-weight: 500 !important;
  font-style: italic !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-breadcrumb a:hover,
:where(.css-1w4v3hc).ant-breadcrumb a:hover {
  color: rgba(0, 0, 0, 0.88);
  background-color: transparent !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-breadcrumb
  .ant-breadcrumb-separator,
:where(.css-1w4v3hc).ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 5px;
  color: rgba(0, 0, 0, 0.45);
}
