@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

.resetPasswordContainer {
  font-family: "Open Sans", sans-serif;
  width: 390px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px auto;
  /* padding:0 10px; */
}
.resetPasswordLogo {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 auto 60px;
  padding: 0;
}
.logoTitle {
  display: flex;
  flex-direction: column;
}

.logoTitle .logoTitle1 {
  font-size: 40px;
  padding: 0;
  font-weight: 500;
  color: #231f20;
}
.logoTitle .logoTitle2 {
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
  font-style: italic;
  margin-top: -8px;
  font-weight: 200;
  color: #231f20;
  padding-left: 5px;
}

.resetPasswordTitle {
  text-align: center;
}
.resetPasswordTitle .title1 {
  font-size: 36px;
  font-weight: 700;
  color: #ec2025;
  margin: 10px 0;
}
.resetPasswordTitle .title2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #231f20;
}
.resetPasswordForm {
  /* width: 390px; */
  margin-top: 40px;
  padding: 0px;
}
.resetPasswordForm .resetPasswordLable {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #231f20;
}
.resetPasswordForm .resetPasswordInput {
  height: 58px;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0;
}
.resetPasswordForm .resetPasswordBtn {
  background-color: #ec2025;
  color: #fff;
  width: 100%;
  height: 56px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border-color: #fff;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
/* .resetPasswordForm .resetPasswordBtn :hover{
  color: #fff;
} */
.note {
  text-align: right;
  color: #ec2025;
  float: right;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  /* cursor: pointer; */
}

.resetPasswordForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input,
.resetPasswordForm
  :where(.css-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  padding-left: 10px;
  border-radius: 0;
  background-color: #ebebeb;
  outline: none;
  color: gray;
}
.resetPasswordForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.resetPasswordForm
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #000;
  background-color: #fff;
  border-color: #ec2025;
}
.resetPasswordForm .resetPasswordAuthBtn {
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 10px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid;
  cursor: pointer;
  border-color: #ec2025;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
.resetPasswordForm .resetPasswordAuthBtn:hover {
  background-color: #ec2025 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.resetPasswordForm .hint1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin: 30px 0 10px;
}
.resetPasswordForm .hint2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #ec2025;
  cursor: pointer;
}

@media (max-width: 405px) {
  /* .resetPasswordContainer{
    width: 200px;
  } */
  .resetPasswordForm {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .resetPasswordContainer {
    width: unset;
  }
  .resetPasswordTitle .title1 {
    font-size: 20px;
    margin: 0px;
  }
  .resetPasswordForm {
    padding: 10px 20px;
    margin-top: 0px;
  }
  .resetPasswordForm .resetPasswordLable {
    font-size: 14px;
    margin-top: 5px;
  }
  .resetPasswordForm .resetPasswordInput {
    height: 40px !important;
    margin: 5px 0;
  }
  .resetPasswordForm .resetPasswordBtn {
    height: 40px !important;
    margin: 5px 0;
    font-size: 13px;
  }
  .resetPasswordForm .hint1,
  .resetPasswordForm .hint2 {
    font-size: 14px;
    margin: 10px;
  }
}
