.drawerDiv .infoBtn:hover,
.my-profile-btns-mobile .infoBtn:hover {
  background-color: #ec2025 !important;
  color: white !important;
  border: none !important;
}
.drawerDiv .infoBtn,
.my-profile-btns-mobile .infoBtn {
  width: unset !important;
  padding: 0.5em 2em;
}
.allProducts
  :where(
    .css-dev-only-do-not-override-1w4v3hc
  ).ant-skeleton.ant-skeleton-element,
.allProducts :where(.css-1w4v3hc).ant-skeleton.ant-skeleton-element {
  display: inline-block;
  width: 100% !important;
}
.mobileBar .MenuItem {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: rgb(35, 31, 32);
}

.mobileBar .header-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: rgb(236, 32, 37);
  font-size: 20px;
  font-weight: 600;
}
.border-item {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}
.mobileBar .NavItem {
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}
.mobileBar .sidebar-header {
  padding: 12px 24px 12px 32px;
  text-transform: capitalize;
}
.mobileBar .log-icon {
  font-size: 22px;
  font-weight: 600;
  /* color:  rgb(236, 32, 37) */
  color: rgba(0, 0, 0, 0.45);
}
