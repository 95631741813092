@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

.myProfile {
  /* width: 100%; */
  /* padding:30px 50px; */
  margin-top: 20px;
  /* height: 100vh; */
}
.profileComponents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2em 0;
  gap: 40px !important;
}
.profileinfo {
  width: 30% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  grid-template-columns: auto;
  gap: 10px;
  padding: 0em;
}
.profileForm {
  /* width: 68% !important; */
  /* display: grid;
    grid-template-columns: auto;
    gap: 10px;
    padding: 1em; */
}
.userInfo {
  /* width: fit-content; */
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  gap: 30px;
}
.imageDiv {
  display: flex;
  align-items: center;
  position: relative;
  /* width: fit-content; */
}
.profileImg {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.editIcon {
  position: absolute;
  bottom: 15px;
  right: 5px;
  font-size: 30px;
  cursor: pointer;
}
.userIcon {
  position: absolute;
  top: 15px;
  right: 5px;
  font-size: 26px;
  cursor: pointer;
}
.titleDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.titleDiv .title {
  font-size: 24px;
  font-weight: 700;
}
.titleDiv .subTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.titleDiv .subTitle span {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-style: italic;
}
.titleDiv .subTitle span p {
  color: #ec2025;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}
.copyIcon {
  font-size: 20px;
  cursor: pointer;
}
.userInfoBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submySingleOrderButons .infoBtn {
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  background: transparent;
  border: 1px solid #ec2025;
  /* padding: 1em 3em; */
  height: 56px;
  width: 151px;
  position: absolute;
  right: 0;
  z-index: 99;
}
.submySingleOrderButons
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.submySingleOrderButons
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff;
  border-color: #fff;
  background: #ec2025;
}

.userVendormySingleOrder {
  /* width: 389px; */
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-content: center;
  justify-self: center;
  padding: 2em 0;
  margin: 0em 0 0em;
}
.vendorImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vendorTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.vendorTitle span {
  font-size: 24px;
  font-weight: 700;
}
.vendorDescription {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vendorDescription p {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.vendorBtnComp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.vendorHint {
  margin-top: 0.8em;
  text-align: right;
  color: #ec2025;
  float: right;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.shoppingBtn .vendorBtn {
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  background: #fff;
  color: #231f20;
  border: 1px solid #ec2025;
  /* padding: 1em 3em; */
  height: 56px;
  width: 100%;
}
.shoppingBtn2 .vendorBtn2 {
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  background: #ec2025;
  color: #fff;
  border: 1px solid #ec2025;
  /* padding: 1em 3em; */
  height: 56px;
  width: 390px;
}
.Btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 1.5em;
  flex-wrap: wrap;
}
.shoppingBtn
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.shoppingBtn
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff;
  border-color: #ec2025;
  background: #ec2025;
}
.shoppingBtn2
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.shoppingBtn2
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #231f20;
  border-color: #ec2025;
  background: #fff;
}
.profileFormComp {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
}
.profileFormComp form .profileUserFormInfo {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}
.submySingleOrderButons .profileInput {
  height: 58px;
  width: 390px;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0;
}
.profileForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input,
.profileForm :where(.css-1w4v3hc).ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  padding-left: 10px;
  border-radius: 0;
  background-color: #ebebeb;
  outline: none;
  color: gray;
}
.profileFormTitle {
  font-size: 24px;
  font-weight: 700;
}
.profileLable {
  font-size: 16px;
  font-weight: 500;
}
.mySingleOrderTable {
  display: flex;
  margin-top: 20px;
}
.mySingleOrderTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mySingleOrderTitle .title1 {
  font-size: 24px;
  font-weight: 700;
}
.mySingleOrderTitle .title2 {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.subCategoryData {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 70px 0px 25px;
}
.mySingleOrderTableCol .submySingleOrderTitle1 {
  font-size: 24px;
  font-weight: 700;
  color: #ec2025;
  margin-bottom: 30px;
}
.mySingleOrderTableCol .submySingleOrderTitle {
  font-style: italic;
  color: #ec2025;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  /* height: 30px; */
}
.mySingleOrderTableCol .submySingleOrderTitle2 {
  font-size: 16px;
  text-align: left !important;
  font-weight: 500;
  /* cursor: pointer; */
  color: #231f20;
  /* width: 50%;   */
}
.mySingleOrderTableCol .submySingleOrderTitle3 {
  font-size: 16px;
  font-weight: 700;
  /* cursor: pointer; */
  color: #231f20;
  /* width: 50%; */
  /* margin-bottom: -15px; */
}
.mySingleOrderTableCol {
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* justify-content: start;
    align-items:start;
    text-align: left; */
}
.submySingleOrderButons {
  display: flex;
  align-items: center;
  gap: 0px;
  position: relative;
}
.trushIcon {
  font-size: 25px;
  color: #ec2025;
  cursor: pointer;
  /* border: 1px solid #EC2025; */
}
.specificationsComp {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  /* margin-bottom: -10px;   */
}
.specifications {
  padding: 4px 8px 4px 8px;
  background: #acaab6;
  color: #fff;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  white-space: nowrap !important;
  /* width: fit-content; */
  /* margin: 10px; */

  font-size: 12px;
  font-weight: 700;
}
.deliveryAddress {
  font-size: 16px;
  font-weight: 500;
  /* position: absolute; */
  /* top:-20px */
}
.mySingleOrderActionBtn {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 110px !important;
}
.calculations {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  flex-wrap: wrap;
}
.calculationsTitle {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;
}
.calculationsTitle .title1 {
  font-size: 16;
  font-weight: 500;
  color: #231f20;
}
.singleOrdertotal {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 0px;
  flex-wrap: wrap;
}
.singleOrdertotal .totalTitle {
  font-size: 16px;
  font-weight: 500;
  color: #231f20;
}
.singleOrdertotal .totalTitle1 {
  font-size: 24px;
  font-weight: 700;
  color: #231f20;
}
.promoCodeForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.datetitle {
  font-size: 16px;
  font-weight: 500;
}
.suborderHistoryTitle2 {
  font-size: 20px;
  font-weight: 700;
  padding: 7px;
}
@media all and (max-width: 992px) {
  .profileComponents {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    justify-self: center !important;
  }
  .profileinfo {
    margin: auto;
    width: auto;
  }
  .profileForm {
    width: auto;
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    padding: 0em;
  }
  .profileFormComp form .profileUserFormInfo {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }
  .userVendor {
    margin: 1em 0 0em;
  }
  .profileComponents {
    width: 100% !important;
  }
  .profileinfo {
    width: 100% !important;
  }
  .profileForm {
    width: 100% !important;
  }
}
/* @media all and (min-width: 650px) and (max-width: 767px) {
    
}
@media all and (min-width: 650px) and (max-width: 767px) {
    
} */

@media screen and (max-width: 767px) {
  .profileComponents {
    padding: 0px !important;
    gap: 0px !important;
  }
  .profileFormTitle {
    font-size: 16px;
  }
  .profileLable {
    font-size: 14px;
    margin-top: 5px;
  }
  .profileFormComp {
    gap: 0px !important;
  }
  .profileForm .profileInput {
    height: 40px !important;
    margin: 5px 0;
  }
  .vendorBtn,
  .infoBtn {
    height: 40px !important;
    margin: 5px 0;
    font-size: 13px;
  }
  .userVendormySingleOrder {
    padding: 0px;
    gap: 5px;
  }
  .userInfo {
    gap: 5px;
  }
  .mySingleOrderActionBtn {
    flex-direction: column;
    margin-bottom: 100px;
    margin-top: 15px !important;
  }
  .mySingleOrderTitle .title1 {
    font-size: 20px;
    margin: 0px;
  }
  .suborderHistoryTitle2 {
    font-size: 12px;
    font-weight: 500;
  }
}
