.container-breadcrumb {
  padding: 0px 0px 15px 90px;
  margin-bottom: 20px;
}
.tab-item-style {
  display: flex;
  align-items: center;
  color: #231f20;
  font-size: 17px;
  font-weight: 600;
  line-height: 14px;
  padding: 15px 0px 15px 90px;
  cursor: pointer;
  margin-bottom: 5px;
}
.tab-item-style:hover,
.tab-item-style.active {
  color: #fff;
  background: #ec2025;
}

.mobile-item-style,
.mobile-item-style.inactive {
  background: #ebebeb;
  border-radius: 7px;
  padding: 5px 8px;
  margin-right: 5px;
  white-space: nowrap;
}
.mobile-item-style.active {
  color: #fff;
  background: #ec2025;
}
.profile-box {
  display: flex;
  padding-top: 10px;
}
.profile-box .image-container {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileInput {
  height: 45px;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
}
.ant-input-affix-wrapper > input.ant-input {
  background-color: #ebebeb !important;
}
.dialog-cancel-btn {
  border: none !important;
  padding: 0.5em 0.5em;
  width: 80px;
  color: #231f20 !important;
  border-radius: 5px;
  box-shadow: 0 0 4px gray;
  margin: 0px 10px;
  cursor: pointer;
}
.dialog-submit-btn {
  padding: 0.6em 0.5em;
  width: 80px;
  border-radius: 5px;
  color: white !important;
  border: none !important;
  box-shadow: 0 0 4px gray;
  background-color: rgba(236, 32, 37, 1) !important;
  cursor: pointer;
}
.specifications {
  padding: 4px 8px 4px 8px;
  background: #acaab6;
  color: #fff;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  white-space: nowrap !important;
  /* width: fit-content; */
  /* margin: 10px; */
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
  margin-bottom: 5px;
}
.btn-height {
  height: 45px;
}
