@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

.loginContainer {
  font-family: "Open Sans", sans-serif;
  margin: 30px auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 110px;
  /* padding:0 10px; */
}
.loginLogo {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 auto 50px;
  padding: 0;
}
.logoTitle {
  display: flex;
  flex-direction: column;
}

.logoTitle .logoTitle1 {
  font-size: 40px;
  padding: 0;
  font-weight: 500;
  color: #231f20;
}
.logoTitle .logoTitle2 {
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
  font-style: italic;
  margin-top: -8px;
  font-weight: 200;
  color: #231f20;
  padding-left: 5px;
}

.loginTitle {
  text-align: center;
}
.loginTitle .title1 {
  font-size: 36px;
  font-weight: 700;
  color: #ec2025;
  margin: 10px 0;
}
.loginTitle .title2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #231f20;
}
.loginForm {
  /* width: 390px; */
  margin-top: 20px;
  padding: 0px;
}
.loginForm .loginLable {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #231f20;
  margin-top: 10px;
}
.loginForm .loginInput {
  height: 58px !important;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0;
}
.loginForm .loginBtn {
  background-color: #ec2025;
  color: #fff;
  width: 100%;
  height: 56px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border-color: #fff;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
/* .loginForm .loginBtn :hover{
  color: #fff;
} */
.forgotPass {
  text-align: right;
  color: #ec2025;
  float: right;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.loginForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.loginForm
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #000;
  background-color: #fff;
  border-color: #ec2025;
}
.loginForm .loginAuthBtn {
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 10px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid;
  cursor: pointer;
  border-color: #ec2025;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
.loginForm .loginAuthBtn:hover {
  background-color: #ec2025 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.loginForm .hint1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin: 30px 0 10px;
}
.loginForm .hint2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #ec2025;
  cursor: pointer;
}
.loginForm .resetPasswordLable {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #231f20;
}
.loginForm .resetPasswordInput {
  height: 58px;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0;
}
.loginForm .resetPasswordBtn {
  background-color: #ec2025;
  color: #fff;
  width: 100%;
  height: 56px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border-color: #fff;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
/* .loginForm .resetPasswordBtn :hover{
  color: #fff;
} */
.note {
  text-align: right;
  color: #ec2025;
  float: right;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  /* cursor: pointer; */
}

.loginForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input,
.loginForm :where(.css-1w4v3hc).ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  /* font-size: 16px !important; */
  border: none;
  padding-left: 10px;
  border-radius: 0;
  background-color: #ebebeb;
  outline: none;
  color: gray;
}
.loginForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.loginForm
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #000;
  background-color: #fff;
  border-color: #ec2025;
}
.loginForm .resetPasswordAuthBtn {
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 10px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid;
  cursor: pointer;
  border-color: #ec2025;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
.loginForm .resetPasswordAuthBtn:hover {
  background-color: #ec2025 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.loginForm .hint1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin: 30px 0 10px;
}
.loginForm .hint2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #ec2025;
  cursor: pointer;
}
/* input[type="text"] {
  font-size: 24px;
} */
@media (max-width: 405px) {
  .loginContainer {
    width: 100%;
  }
  .loginForm {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .loginContainer {
    width: 100%;
  }
  .loginTitle .title1 {
    font-size: 20px;
    margin: 0px;
  }
  .loginTitle .title2 {
    font-size: 14px;
  }
  .loginForm {
    padding: 10px 20px;
    margin-top: 0px;
  }
  .loginForm .loginLable {
    font-size: 14px;
    margin-top: 5px;
  }
  .loginForm .loginInput,
  .loginForm .resetPasswordInput {
    height: 40px !important;
    margin: 5px 0;
  }
  .loginForm .loginBtn,
  .loginForm .loginAuthBtn,
  .loginForm .resetPasswordBtn,
  .loginForm .resetPasswordAuthBtn {
    height: 40px;
    margin: 5px 0px;
    font-size: 13px;
  }
  .loginForm .hint1,
  .loginForm .hint2 {
    font-size: 14px;
    margin: 10px;
  }

  .loginForm .resetPasswordLable {
    font-size: 14px;
  }
}
