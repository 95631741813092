@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

.myProfile {
  /* width: 100%; */
  /* padding:30px 50px; */
  margin-top: 20px;
  /* height: 100vh; */
}
.profileComponents {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2em 0 0;
}
.profileinfo {
  width: 30%;
  display: grid;
  justify-content: center;
  grid-template-columns: auto;
  gap: 10px;
  padding: 0em;
}
.profileForm {
  width: 70%;
  /* display: grid;
    grid-template-columns: auto;
    gap: 10px;
    padding: 1em; */
}
.userInfo {
  /* width: fit-content; */
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  gap: 30px;
}
.imageDiv {
  display: flex;
  align-items: center;
  position: relative;
  /* width: fit-content; */
}
.profileImg {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.editIcon {
  position: absolute;
  bottom: 15px;
  right: 5px;
  font-size: 30px;
  cursor: pointer;
}
.userIcon {
  position: absolute;
  top: 15px;
  right: 5px;
  font-size: 26px;
  cursor: pointer;
}
.titleDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.titleDiv .title {
  font-size: 24px;
  font-weight: 700;
}
.titleDiv .subTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.titleDiv .subTitle span {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-style: italic;
}
.titleDiv .subTitle span p {
  color: #ec2025;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}
.copyIcon {
  font-size: 20px;
  cursor: pointer;
}
.userInfoBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.subwishListButons .infoBtn {
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border: 1px solid #ec2025;
  /* padding: 1em 3em; */
  height: 46px;
  width: 151px;
}
.subwishListButons
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.subwishListButons
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff;
  border-color: #fff;
  background: #ec2025;
}

.userVendor {
  width: 100% !important;
  /* width: 389px; */
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-content: center;
  justify-self: center;
  padding: 4em 0 0;
  margin: 1em 0 2em;
}
.vendorImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vendorTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.vendorTitle span {
  font-size: 24px;
  font-weight: 700;
}
.vendorDescription {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vendorDescription p {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.vendorBtnComp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.vendorHint {
  margin-top: 0.8em;
  text-align: right;
  color: #ec2025;
  float: right;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.vendorBtn {
  font-size: 16px;
  font-weight: 500;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border: 1px solid #ec2025;
  /* padding: 1em 3em; */
  height: 56px;
  width: 100%;
}
.categories-btns-mobile {
  background: #ec2025;
  color: #fff;
  border: 1px solid #ec2025;
}
.categories-btns-mobile:hover {
  background: #fff;
  color: #231f20;
  border: 1px solid #ec2025;
}
.vendorBtnComp
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.vendorBtnComp
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #231f20;
  border-color: #ec2025;
  background: #fff;
}
.profileFormComp {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
}
.profileFormComp form .profileUserFormInfo {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}
.profileForm .profileInput {
  height: 58px;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0;
}
.profileForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input,
.profileForm :where(.css-1w4v3hc).ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  padding-left: 10px;
  border-radius: 0;
  background-color: #ebebeb;
  outline: none;
  color: gray;
}
.profileFormTitle {
  font-size: 24px;
  font-weight: 700;
}
.profileLable {
  font-size: 16px;
  font-weight: 500;
}
.wishListTable {
  display: flex;
  margin-top: 20px;
  max-height: 500px;
  overflow: auto;
}
.wishListTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wishListTitle .title1 {
  font-size: 24px;
  font-weight: 700;
}
.wishListTitle .title2 {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.subCategoryData {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 70px 0px 25px;
}
.wishListTableCol .subwishListTitle1 {
  font-size: 24px;
  font-weight: 700;
  color: #ec2025;
  margin-bottom: -16px;
}
.wishListTableCol .subwishListTitle {
  font-style: italic;
  color: #ec2025;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  /* height: 30px; */
}
.wishListTableCol .subwishListTitle2 {
  font-size: 16px;
  text-align: left !important;
  font-weight: 500;
  /* cursor: pointer; */
  color: #231f20;
  /* width: 50%;   */
}
.wishListTableCol .subwishListTitle3 {
  font-size: 16px;
  font-weight: 700;
  /* cursor: pointer; */
  color: #231f20;
  /* width: 50%; */
  /* margin-bottom: -15px; */
}
.wishListTableCol {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.subwishListButons {
  display: flex;
  align-items: center;
  gap: 15px;
}
.trushIcon {
  font-size: 25px;
  color: #ec2025;
  cursor: pointer;
  /* border: 1px solid #EC2025; */
}
@media all and (max-width: 992px) {
  .profileComponents {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    justify-self: center !important;
  }
  .profileinfo {
    margin: auto;
    width: auto;
  }
  .profileForm {
    width: auto;
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
    padding: 0em;
  }
  .profileFormComp form .profileUserFormInfo {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
  }
  .userVendor {
    margin: 1em 0 0em;
  }
  .profileComponents {
    width: 100% !important;
  }
  .profileinfo {
    width: 100% !important;
  }
  .profileForm {
    width: 100% !important;
  }
}
/* @media all and (min-width: 650px) and (max-width: 767px) {
    
}
@media all and (min-width: 650px) and (max-width: 767px) {
    
} */
