ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.automotive-card-container {
  width: 100%;
  border-radius: 7px;
}

.automotive-card-container .item-header {
  max-width: 100%;
  min-height: 1px;
  position: relative;
}
.automotive-card-container .tags-wrap {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
  display: flex;
  align-items: center;
}
/* .listing-image-wrap {
  position: relative;
}
.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); 
  color: #f1f1f1;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
} */
.automotive-card-container .img-fluid {
  max-width: 100%;
  height: 220px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 10px;
}
.automotive-card-container .tags-wrap span {
  text-transform: capitalize;
}
.automotive-card-container .item-body {
  padding: 10px 5px;
}
.automotive-card-container .flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.automotive-card-container .item-title {
  font-weight: 500;
  color: #ec2025;
  cursor: pointer;
  /* line-height: 1.2; */
  text-transform: capitalize;
  font-size: 16px;
  text-align: left;
}
.automotive-card-container .item-address {
  color: #231f20;
  font-size: 12px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  font-style: normal;
  line-height: inherit;
  text-align: left;
  /* height: 30px; */
}

@media screen and (max-width: 850px) {
  .automotive-card-container .img-fluid {
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .automotive-card-container .tags-wrap {
    right: 0px;
  }
  .automotive-card-container .tags-wrap span {
    right: 0px;
    font-size: 10px;
  }
  .automotive-card-container .img-fluid {
    height: 150px;
  }
  .automotive-card-container .item-title {
    font-size: 14px;
  }
  .automotive-card-container .item-address {
    font-size: 12px;
  }
}
