* {
  margin: 0;
  padding: 0;
}
.capitalize-text {
  text-transform: capitalize;
}
.unset-text-transform {
  text-transform: none;
}
/**inputs border color**/
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper,
.ant-input-affix-wrapper,
.ant-col input {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
*:hover :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper,
.ant-input-affix-wrapper {
  border-color: #d9d9d9 !important;
  outline: none !important;
  outline: 1px solid #d9d9d9 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input,
.ant-col .input {
  border-color: #d9d9d9 !important;
}
*:hover
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:hover,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input:hover,
.ant-col .input:hover {
  border-color: #d9d9d9 !important;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus {
  border-color: #d9d9d9 !important;
  outline: none !important;
  outline: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:hover,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input:focus,
.ant-col .input:focus {
  border-color: #d9d9d9 !important;
}
.ant-input-affix-wrapper input {
  border: none !important;
  outline: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bGpMiD {
  margin: 0px !important;
}
.range-slider .range-slider__thumb,
.range-slider .range-slider__range {
  background: #ec2025 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-number:hover,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-number-focused {
  border-color: #ec2025 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: white !important;
}
/* :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  color: white !important;
  border-color: #ec2025 !important;
  background: #ec2025 !important;
} */
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-number
  .ant-input-number-handler:hover
  .ant-input-number-handler-up-inner,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-input-number
  .ant-input-number-handler:hover
  .ant-input-number-handler-down-inner {
  color: #ec2025 !important;
}
:where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  border-color: #ec2025 !important;
}
/* .anticon:hover {
  color: #ec2025 !important;
} */
/* :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: white !important;
  border-color: #ec2025 !important;
  background: #ec2025 !important;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
:where(.css-1w4v3hc).ant-input:hover {
  border-color: #ec2025 !important;
}
.App-link {
  color: #61dafb;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-slider .ant-slider-track {
  background-color: #ec2025 !important;
}
/* .gnvGf {
  overflow: visible !important;
} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.white-btn {
  color: #231f20 !important;
  background-color: #fff !important;
  border-color: #ec2025 !important;
  font-size: 16px;
  font-weight: 500;
}
.red-btn {
  color: #fff !important;
  background-color: #ec2025 !important;
  border-color: #ec2025 !important;
  font-size: 16px;
  font-weight: 500;
}
/* .hover-bg-red:focus  */
.hover-bg-red:hover,
.hover-bg-red:active {
  color: #fff !important;
  background-color: #ec2025 !important;
  border-color: #ec2025 !important;
}

/* .hover-bg-w:focus  */
.hover-bg-w:hover,
.hover-bg-w:active {
  color: #231f20 !important;
  background-color: #fff !important;
  border-color: #ec2025 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-tabs
  .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-1w4v3hc).ant-tabs
  .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-1w4v3hc).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-tabs
  .ant-tabs-tab-remove:active,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-tabs .ant-tabs-tab:hover {
  color: #ec2025 !important;
}
/* .ant-tabs-tab-active {
  border-bottom: 2px solid #ec2025 !important;
} */
:where(.css-dev-only-do-not-override-1w4v3hc).ant-tabs .ant-tabs-ink-bar {
  background: #ec2025 !important;
}
.ant-tabs-tab {
  font-size: 16px !important;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #fff !important;
  scroll-behavior: smooth;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector,
.ant-select-focused:where(.css-dev-only-do-not-override-1w4v3hc).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #ec2025 !important;
}
.ant-select-focused:where(.css-1w4v3hc).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #ec2025 !important;
}
:where(.css-1w4v3hc).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #ec2025 !important;
}
:where(.css-1w4v3hc).ant-tabs .ant-tabs-ink-bar {
  background: #ec2025 !important;
}
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab-remove:active {
  color: #ec2025 !important;
}
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab:hover {
  color: #ec2025 !important;
}
:where(.css-1w4v3hc).ant-input-number:hover,
:where(.css-1w4v3hc).ant-input-number:focus,
.ant-input-number-focused {
  border-color: #ec2025 !important;
}
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-1w4v3hc).ant-tabs .ant-tabs-tab-remove:active {
  color: #ec2025 !important;
}

:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  background-color: white !important;
}
.ant-picker-focused {
  border-color: gray !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid #ec2025 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc) a,
:where(.css-1w4v3hc) a {
  color: #ec2025 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker:hover,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker-focused,
:where(.css-1w4v3hc).ant-picker:hover,
:where(.css-1w4v3hc).ant-picker-focused {
  border-color: #ec2025 !important;
}
/* .ant-picker-cell-inner {
  background: #ec2025 !important;
} */
/* .ant-btn-primary {
  background-color: #ec2025 !important;
}
.ant-picker-time-panel-cell-inner {
  background: #ec202512 !important;
} */

:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner,
:where(.css-1w4v3hc).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
:where(.css-1w4v3hc).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
:where(.css-1w4v3hc).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background: #ec2025 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-primary,
:where(.css-1w4v3hc).ant-btn-primary {
  background-color: #ec2025 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner,
:where(.css-1w4v3hc).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #ec202512 !important;
}
:where(.css-dev-only-do-not-override-1w4v3hc).ant-pagination
  .ant-pagination-item-active,
:where(.css-1w4v3hc).ant-pagination .ant-pagination-item-active {
  border-color: #ec2025 !important;
}
.ant-pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
