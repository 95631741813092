@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap');

*{
    font-family: 'Open Sans', sans-serif;
}



.footer-main-div {
  background-color: rgba(236, 32, 37, 1);
  padding: 1em 4em 1em;
  margin-top: 60px;
}
.top-div {
  display: flex;

  width: 90%;
  margin:20px auto;
}
.logo-parent {
  /* width: 100%; */
  width: 38%;
}
.footer-logo {
  width: 60%;
  height: 90px;
  
}
.scoial-para {
  margin-top: 1em;
}
.social-parent {
  display: flex;
  width: 40%;
  justify-content: space-between;
  margin-top: 0.5em;
  gap: 10px;
}
.social-icons {
  width: 25px;
  height: 25px;
}
.data-parent {
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 2em 0em 1em;
}
.data-sub-div {
  display: flex;
  justify-content: space-between;
}
.data-head {
  font-size: 17px;
  color: white;
  font-weight: 700;
  margin: 2em 0.5em 1.2em;
  /* box-sizing: border-box; */
}
.data-para {
  font-size: 12px;
  color: white;
  margin: 1em 0.5em 0em;
  font-weight: 500;
  /* box-sizing: border-box; */
}
.bottom-para-parent {
  display: flex;
  justify-content: space-between;
  /* margin-top: 4em; */
  width: 90%;
  margin:4em auto 0;
}
.bottom-para {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 12px;
}

@media all and (min-width: 768px) and (max-width: 900px) {
  .footer-main-div {
    padding: 1em 2em 1em;
  }
  .logo-parent {
    width: 35%;
  }
  .data-parent {
    width: 65%;
    padding: 4em 0em 0.5em;
  }
  .footer-logo {
    width: 80%;
  }
  .social-parent {
    width: 60%;
  }
  .social-icons {
    width: 20px;
    height: 20px;
  }
}

@media all and (min-width: 650px) and (max-width: 767px) {
  .footer-main-div {
    padding: 1em 2em 1em;
  }
  .top-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo-parent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .data-parent {
    width: 90%;
    padding: 4em 0em 0.5em;
  }
  .footer-logo {
    width: 80%;
    margin: auto;
  }
  .scoial-para {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 3em !important; */
  }
  .social-parent {
    width: 50%;
  }
  .social-icons {
    width: 20px;
    height: 20px;
  }
}

@media all and (min-width: 450px) and (max-width: 649px) {
  .footer-main-div {
    padding: 1em 2em 1em;
  }
  .top-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo-parent {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .data-parent {
    width: 100%;
    padding: 4em 0em 0.5em;
    
  }
  .footer-logo {
    width: 80%;
    margin: auto;
  }
  .scoial-para {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .social-parent {
    width: 60%;
  }
  .social-icons {
    width: 20px;
    height: 20px;
  }
  .bottom-para {
    margin: 1em;
  }
  .bottom-para-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media all and (max-width: 449px) {
    .footer-main-div {
        padding: 1em 2em 1em;
      }
      .top-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .logo-parent {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .data-parent {
        width: 100%;
        padding: 4em 0em 0.5em;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        
      }
      .footer-logo {
        width: 80%;
        margin: auto;
      }
      .scoial-para {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .social-parent {
        width: 60%;
      }
      .social-icons {
        width: 20px;
        height: 20px;
      }
      .bottom-para {
        margin: 1em;
      }
      .bottom-para-parent {
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items: flex-start;
      }
}
