@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

.signupContainer {
  font-family: "Open Sans", sans-serif;
  margin: 10px auto;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  /* padding:0 10px; */
}
.signupLogo {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 0 auto 10px;
  padding: 0;
}
.logoTitle {
  display: flex;
  flex-direction: column;
}

.logoTitle .logoTitle1 {
  font-size: 40px;
  padding: 0;
  font-weight: 500;
  color: #231f20;
}
.logoTitle .logoTitle2 {
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
  font-style: italic;
  margin-top: -8px;
  font-weight: 200;
  color: #231f20;
  padding-left: 5px;
}

.signupTitle {
  text-align: center;
}
.signupTitle .title1 {
  font-size: 36px;
  font-weight: 700;
  color: #ec2025;
  margin: 10px 0;
}
.signupTitle .title2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #231f20;
}
.signupForm {
  /* width: 390px; */
  margin-top: 20px;
  padding: 0px;
}
.signupForm .signupLable {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #231f20;
  margin-top: 10px;
}
.signupForm .signupInput {
  height: 58px !important;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0;
}
.signupForm .signupBtn {
  background-color: #ec2025;
  color: #fff;
  width: 100%;
  height: 56px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border-color: #fff;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
/* .signupForm .signupBtn :hover{
  color: #fff;
} */
.note {
  text-align: right;
  color: #ec2025;
  float: right;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  /* cursor: pointer; */
}
.privecy {
  text-align: left;
  color: #ec2025;
  float: left;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  /* cursor: pointer; */
}

.signupForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input,
.signupForm :where(.css-1w4v3hc).ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  padding-left: 10px;
  border-radius: 0;
  background-color: #ebebeb;
  outline: none;
  color: gray;
}
.signupForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.signupForm
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #000;
  background-color: #fff;
  border-color: #ec2025;
}
.signupForm .signupAuthBtn {
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 10px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid;
  cursor: pointer;
  border-color: #ec2025;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
.signupForm .signupAuthBtn:hover {
  background-color: #ec2025 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.signupForm .hint1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin: 30px 0 10px;
}
.signupForm .hint2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #ec2025;
  cursor: pointer;
}

.signupForm .resetPasswordLable {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #231f20;
}
.signupForm .resetPasswordInput {
  height: 58px;
  background-color: #ebebeb;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 10px 0;
}
.signupForm .resetPasswordBtn {
  background-color: #ec2025;
  color: #fff;
  width: 100%;
  height: 56px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 5px;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  border-color: #fff;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
/* .signupForm .resetPasswordBtn :hover{
  color: #fff;
} */
.note {
  text-align: right;
  color: #ec2025;
  float: right;
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
  /* cursor: pointer; */
}

.signupForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-input-affix-wrapper
  > input.ant-input,
.signupForm :where(.css-1w4v3hc).ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  padding-left: 10px;
  border-radius: 0;
  background-color: #ebebeb;
  outline: none;
  color: gray;
}
.signupForm
  :where(.css-dev-only-do-not-override-1w4v3hc).ant-btn-default:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
.signupForm
  :where(.css-1w4v3hc).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #000;
  background-color: #fff;
  border-color: #ec2025;
}
.signupForm .resetPasswordAuthBtn {
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  margin: 20px 0 10px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid;
  cursor: pointer;
  border-color: #ec2025;
  transition: ease 0.5s;
  -webkit-transition: ease 0.5s;
  -moz-transition: ease 0.5s;
  -ms-transition: ease 0.5s;
  -o-transition: ease 0.5s;
}
.signupForm .resetPasswordAuthBtn:hover {
  background-color: #ec2025 !important;
  color: #fff !important;
  border-color: #fff !important;
}
.signupForm .hint1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  margin: 30px 0 10px;
}
.signupForm .hint2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #ec2025;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .signupTitle .title1 {
    font-size: 20px;
    margin: 0px;
  }
  .signupTitle .title2 {
    font-size: 14px;
  }
  .signupForm {
    padding: 20px 20px 40px 20px;
    margin-top: 0px;
  }
  .signupForm .signupLable {
    font-size: 14px;
    margin-top: 5px;
  }
  .signupForm .signupInput {
    height: 40px !important;
    margin: 5px 0;
  }
  .signupForm .signupBtn {
    height: 40px;
    margin: 5px 0px;
  }
  .signupForm .hint1 {
    font-size: 14px;
    margin: 10px;
  }
}
@media (max-width: 405px) {
  .signupContainer {
    width: 100%;
  }
  .logoTitle .logoTitle1 {
    font-size: 30px !important;
  }
  .logoTitle .logoTitle2 {
    font-size: 16px !important;
  }
  .signupLogo img {
    width: 20%;
  }
  .signupForm {
    padding: 20px 20px 40px 20px;
  }
  .signupForm .hint2 {
    font-size: 14px;
    margin: 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .signupContainer {
    width: 100%;
  }
  .signupForm .signupBtn {
    font-size: 13px;
  }
}
